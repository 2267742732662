// CreateSellerForm.js

import React, { useState } from 'react';

const CreateSellerForm = ({ onSubmit }) => {
  const [sellerInfo, setSellerInfo] = useState({
    name: '',
    contact: '',
    assetType: '',
    price: '',
    units: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSellerInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(sellerInfo);
    // You can reset the form or perform other actions after submission
    setSellerInfo({
      name: '',
      contact: '',
      assetType: '',
      price: '',
      units: '',
    });
  };

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Create New Seller</h2>
      <form onSubmit={handleSubmit} className="max-w-md">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={sellerInfo.name}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contact">
            Contact Information
          </label>
          <input
            type="text"
            id="contact"
            name="contact"
            value={sellerInfo.contact}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="assetType">
            Asset Type
          </label>
          <input
            type="text"
            id="assetType"
            name="assetType"
            value={sellerInfo.assetType}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
            Price
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={sellerInfo.price}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="units">
            Units
          </label>
          <input
            type="number"
            id="units"
            name="units"
            value={sellerInfo.units}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Create Seller
        </button>
      </form>
    </div>
  );
};

export default CreateSellerForm;
