// App.js

import React, { useState } from 'react';
import AssetListing from './components/assetListing';
import CreateSellerForm from './components/createNewSellerForm';
import Footer from './components/footer'
import SEO from './components/seo';
import { seoTopics } from './config/topics'
import { whatsappNumber } from './config/whatsapp';


const App = () => {
  const initialAssets = seoTopics
  

  const [assets, setAssets] = useState(initialAssets);
  const [showAssetListing, setShowAssetListing] = useState(true);

  const handleSellClick = () => {
    // Use your preferred method to toggle between components
    setShowAssetListing(false);
  };

  const handleSellerSubmit = (sellerInfo) => {
    setAssets((prevAssets) => [...prevAssets, sellerInfo]);
    // Use your preferred method to toggle between components
    setShowAssetListing(true);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <SEO />
        <nav className="bg-white p-4 text-black flex flex-col sm:flex-row justify-between items-center">
          <div className="text-xl font-bold font-helvetica mb-2 sm:mb-0">
            SEO ACADEMY
          </div>
          <div className="flex flex-col sm:flex-row">
            <button
              onClick={() => {

                window.location.href = 'https://ahadisoft.com';

              }}
              className="mb-2 sm:mb-0 mr-4 bg-black font-medium text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              HIRE AN SEO EXPERT
            </button>
            <button
              
              className="bg-black font-medium text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick = {() => {

                window.location.href = `https://wa.me/${whatsappNumber}?text=Hello! I'm interested in an assesment for my skills`;

              }}
            >
              SCHEDULE AN ASSESSMENT
            </button>
          </div>
        </nav>

      <div className="container mx-auto py-8">
        {/* Conditional rendering based on state */}
        {showAssetListing ? (
          <AssetListing assets={assets} />
        ) : (
          <CreateSellerForm onSubmit={handleSellerSubmit} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default App;
