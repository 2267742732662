export const seoTopics = [
    {
      title: 'Introduction to SEO',
      description: 'This lesson covers the definition and importance of SEO. It also touches on the evolution of search engines.',
      price: 100,
    },
    {
      title: 'Search Engine Basics',
      description: 'How search engines work. Understanding search engine algorithms to better understand how SEO works at a deeper level.',
      price: 100,
    },
    {
        title: 'Keyword Research',
        description: 'In-depth understanding of keyword research is essential for identifying the terms your target audience uses. Mastering this topic enables you to create content that resonates with your audience, leading to higher search rankings and increased organic traffic.',
        price: 100,
      },
      {
        title: 'On-Page SEO',
        description: 'Optimizing on-page elements like title tags, meta descriptions, and headers is crucial for search engine visibility. A solid grasp of on-page SEO enhances your ability to communicate effectively with search engines, resulting in better ranking positions and improved click-through rates.',
        price: 100,
      },
      {
        title: 'Backlink Strategies',
        description: 'Understanding white-hat link building techniques is key to building authority and trust in the eyes of search engines. Mastery of backlink strategies empowers you to enhance your the credibility of your website, ultimately leading to improved rankings and increased organic traffic.',
        price: 100,
      },
      {
        title: 'Technical SEO',
        description: 'A strong foundation in technical SEO, including site speed optimization and mobile responsiveness, ensures that search engines can efficiently crawl and index your site. Proficiency in technical SEO results in a well-performing website, positively impacting rankings and user experience.',
        price: 100,
      },
      {
        title: 'Content Creation and Optimization',
        description: 'Creating high-quality, optimized content is at the heart of SEO success. Mastering this topic allows you to produce content that not only satisfies search engine algorithms but also engages and provides value to your audience, driving sustained organic traffic.',
        price: 100,
      },
      {
        title: 'Local SEO',
        description: 'For businesses targeting local audiences, mastering local SEO is paramount. Learning to optimize for local search ensures that your business appears in local search results, driving foot traffic to physical locations and increasing visibility within your community.',
        price: 100,
      },
      {
        title: 'Analytics and Reporting',
        description: 'Proficiency in SEO analytics tools allows you to measure the effectiveness of your strategies. By analyzing data and generating insightful reports, you gain the ability to make informed decisions, refine your approach, and demonstrate the impact of your efforts.',
        price: 100,
      },
      {
        title: 'Mobile-First Optimization',
        description: 'With the increasing prevalence of mobile devices, optimizing for mobile is non-negotiable. Mastering mobile-first optimization ensures that your website caters to the growing mobile user base, positively influencing search rankings and user satisfaction.',
        price: 100,
      },
      {
        title: 'User Experience (UX) and SEO',
        description: 'A seamless user experience is a critical factor for search engine rankings. Understanding how UX impacts SEO allows you to create websites that not only rank well but also provide an enjoyable experience for visitors, leading to increased engagement and conversions.',
        price: 100,
      },
      {
        title: 'Algorithm Adaptation',
        description: 'Staying informed about search engine algorithm updates and adapting strategies accordingly is crucial for long-term SEO success. Mastery in this area ensures that your tactics remain effective despite evolving search engine algorithms, maintaining and improving your rankings over time.',
        price: 100,
      },
  ];
  