// components/SEO.js

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  const title = "Unlock the Power of SEO with SEO Academy";
  const description = "Empowering marketing managers, CEOs, and entrepreneurs to take control of their online presence. Learn SEO with us for a cost-effective alternative to hiring an SEO professional and gain in-depth knowledge tailored for professionals.";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="SEO Academy, SEO courses, digital marketing, online presence, organic traffic" />
      <meta name="author" content="David Mbwana" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      {/* Add other meta tags as needed */}
    </Helmet>
  );
};

export default SEO;
