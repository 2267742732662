// cta.js

import React from 'react';

const CTA = () => {
 

  return (
<div className="bg-white p-6 rounded-lg shadow-md mt-8 mb-8">
  <h3 className="text-xl font-semibold mb-4">Unlock the Power of SEO with our SEO ACADEMY</h3>
  <p className="text-gray-700 mb-4">
    Hiring a professional SEO expert can be expensive, and the costs can quickly add up. At SEO Academy, we believe in empowering marketing managers, CEOs, and entrepreneurs to take control of their online presence.
  </p>
  <p className="text-gray-700 mb-4">
    Learning SEO with us is not only cost-effective but provides you with the knowledge and skills to boost your website's visibility, drive organic traffic, and increase your business's online success.
  </p>
  <ul className="list-disc pl-6 mb-4">
    <li>Cost-effective alternative to hiring an SEO professional.</li>
    <li>Empower yourself with in-depth SEO knowledge tailored for marketing managers, CEOs, and entrepreneurs.</li>
    <li>Gain control over your online presence and digital marketing strategies.</li>
    <li>Access to industry experts and up-to-date SEO practices.</li>
    <li>Flexible learning schedule to fit into your busy professional life.</li>
    <li>Schedule an assesment for yourself or members of your team.</li>
  </ul>
  <p className="text-gray-700">
    Invest in your skills and elevate your business's online performance. Enroll in SEO Academy and become a digital marketing powerhouse!
  </p>
  
</div>

  
  );
};

export default CTA;
