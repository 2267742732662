// AssetListing.js

import React from 'react';
import CTA from './cta'
import { whatsappNumber } from '../config/whatsapp';





const AssetListing = ({ assets }) => {
  const handleBuyClick = (asset, bookOrTutor) => {
    const lessonTitle = asset.title + bookOrTutor
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=Hello! I'm interested in the lesson: ${encodeURIComponent(lessonTitle)}`;

    window.location.href = whatsappLink;
  };

  return (
<div className="container mx-auto mt-8 ">
  
  <CTA />
  <div className="flex flex-wrap -mx-4">
    {assets.map((asset, index) => (
      <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 sm:overflow-x-auto">
        <div className="bg-white p-6 rounded-lg shadow-md">
          {/* Lesson Number Label with Underline and Margin Bottom */}
          <span className="text-sm font-semibold text-gray-600 mb-2 block underline">
            Lesson {index + 1}
          </span>

          {/* Asset Details in a Definition List */}
          <dl>
            <div className="mb-2">
              <dt className="font-semibold inline-block mr-2">Title:</dt>
              <dd className="inline-block">{asset.title}</dd>
            </div>
            <div className="mb-2">
              <dt className="font-semibold inline-block mr-2">Description:</dt>
              <dd className="inline-block">{asset.description}</dd>
            </div>
          </dl>
          
          {/* Buy Button at the Bottom */}
          <button
            className="mt-4 bg-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            onClick={() => handleBuyClick(asset," (Ebook)" )}
          >
            BUY E-BOOK @ {asset.price} Ksh
          </button>

           {/* Buy Button at the Bottom */}
           <button
            className="mt-4 bg-yellow-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            onClick={() => handleBuyClick(asset, " (Tutor)" )}
          >
            GET TUTOR @ 1,000 Ksh Per Hour
          </button>
        </div>
      </div>
    ))}
  </div>
</div>

  
  
  );
};

export default AssetListing;
